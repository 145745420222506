import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalWindowStatus: false,
  error: '',
  message: '',
  policyWindowVisibility: true,
  settigsWindowVisibility: false,
  cookiesPolicy: {
    googleScript: false,
    eclinicCitasWidget: false,
    saveSettings: false,
  },
  language: 'en',
  advertisingBtnVisibility: false,
};

const technical = createSlice({
  name: 'technical',
  initialState,
  reducers: {
    setLanguage: (store, action) => {
      store.language = action.payload;
      if (store.cookiesPolicy.saveSettings) {
        const data = {
          googleScript: store.cookiesPolicy.googleScript,
          eclinicCitasWidget: store.cookiesPolicy.eclinicCitasWidget,
          saveSettings: store.cookiesPolicy.saveSettings,
          language: action.payload,
        };
        localStorage.setItem('ArtStudioDental.data', JSON.stringify(data));
      } else {
        return;
      }
    },
    setSettigsWindowVisibility: (store, action) => {
      store.settigsWindowVisibility = action.payload;
    },
    setCookiesPolicy: (store, action) => {
      store.cookiesPolicy.googleScript = action.payload.googleScript;
      store.cookiesPolicy.eclinicCitasWidget =
        action.payload.eclinicCitasWidget;
      store.cookiesPolicy.saveSettings = action.payload.saveSettings;

      if (store.cookiesPolicy.saveSettings) {
        const data = {
          googleScript: action.payload.googleScript,
          eclinicCitasWidget: action.payload.eclinicCitasWidget,
          saveSettings: action.payload.saveSettings,
          language: store.language,
        };
        localStorage.setItem('ArtStudioDental.data', JSON.stringify(data));
      } else {
        localStorage.removeItem('ArtStudioDental.data');
      }
    },
    setPolicyWindowVisibility: (store, action) => {
      store.policyWindowVisibility = action.payload;
    },
    setModalWindowStatus: (store, action) => {
      store.modalWindowStatus = action.payload;
    },
    setAdvertisingBtnVisibility: (store, action) => {
      store.advertisingBtnVisibility = action.payload;
    },
    clearTechnicalError: store => {
      store.error = '';
    },
    clearTechnicalMessage: store => {
      store.message = '';
    },
  },
  extraReducers: builder => {},
});

export default technical.reducer;
export const {
  setModalWindowStatus,
  clearTechnicalError,
  clearTechnicalMessage,
  setPolicyWindowVisibility,
  setCookiesPolicy,
  setSettigsWindowVisibility,
  setLanguage,
  setAdvertisingBtnVisibility,
} = technical.actions;
