import React, { useEffect, useMemo } from 'react';
import s from './SnowFlake.module.scss';

const SnowFlake = () => {
  const snowflakeChars = useMemo(() => ['❄', '❅', '❆', '✳', '✴', '✵'], []);

  useEffect(() => {
    const createSnowflake = () => {
      const snowflake = document.createElement('div');
      snowflake.classList.add(s.snowflake);
      snowflake.textContent =
        snowflakeChars[Math.floor(Math.random() * snowflakeChars.length)];

      // Випадкове розташування сніжинки
      snowflake.style.left = Math.random() * 100 + 'vw';
      snowflake.style.animationDuration = Math.random() * 3 + 2 + 's'; // Швидкість падіння
      snowflake.style.fontSize = Math.random() * 10 + 10 + 'px'; // Розмір сніжинки

      const container = document.getElementById('snowflakes-container');
      container.appendChild(snowflake);

      // Видалення сніжинки після завершення анімації
      setTimeout(() => {
        snowflake.remove();
      }, 5000);
    };

    // Генерація сніжинок кожні 200 мс
    const interval = setInterval(createSnowflake, 200);

    // Очищення при демонтованому компоненті
    return () => clearInterval(interval);
  }, [snowflakeChars]);

  return (
    <div id="snowflakes-container" className={s.snowflakesContainer}></div>
  );
};

export default SnowFlake;
