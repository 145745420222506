import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { getAdvertisingBtnVisibility } from '../../../../redux/technical/technical-selectors';
import { setAdvertisingBtnVisibility } from '../../../../redux/technical/technical-slice';
import s from './AdvertisingBtn.module.scss';

const AdvertisingBtn = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const { t } = useTranslation();
  const visible = useSelector(getAdvertisingBtnVisibility);
  const dispatch = useDispatch();

  const toggleVisibility = () => {
    dispatch(setAdvertisingBtnVisibility(!visible));
  };

  return (
    <div>
      <button
        className={`${s.adToggleBtn} ${visible ? s.hidden : ''} ${
          s.adWindow__text
        }
        }`}
        onClick={toggleVisibility}
      >
        <span className={s.text}>
          {t(isMobile || isTablet ? 'Offer' : 'Show Offer')}
        </span>
      </button>
    </div>
  );
};
export default AdvertisingBtn;
